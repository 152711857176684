import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";

const config = {
  apiKey: ESBUILD_VAR__FIREBASE_API_KEY,
  authDomain: "wargamesarena-" + ESBUILD_VAR__ENV +".firebaseapp.com",
};

app = initializeApp(config);

const auth = getAuth(app);

export const rawLoginWithEmailAndPassword =
  ({ left, right }) =>
    ({ email, password }) =>
      (emit) =>
        // the purs compiler fails on async arrow functions
        async function () {
          try {
            const result = await signInWithEmailAndPassword(auth, email, password);

            const token = await result.user.getIdToken();

            emit(right(token))();
          } catch (e) {
            console.log(e);
            console.log(`Error: ${e.message}`);
            emit(left(e.message))();
          }
        };

export const rawRegisterWithEmailAndPassword =
  ({ left, right }) =>
    ({ email, password }) =>
      (emit) =>
        // the purs compiler fails on async arrow functions
        async function () {
          try {
            const result = await createUserWithEmailAndPassword(
              auth,
              email,
              password
            );

            const token = await result.user.getIdToken();

            emit(right(token))();
          } catch (e) {
            console.log(e);
            console.log(`Error: ${e.message}`);
            emit(left(e.message))();
          }
        };
