import "./scss/main.scss";
import "bootstrap";

const thePort = ESBUILD_VAR__API_PORT;
const theHost = ESBUILD_VAR__API_HOST;
const theEnv = ESBUILD_VAR__ENV;

require(ESBUILD_VAR__OUTPUT_FOLDER + "/Main/index.js").main({
  isRemote: ESBUILD_VAR__DEPLOY_ENV === "remote",
  host: theHost,
  port: thePort,
  env: theEnv,
})();